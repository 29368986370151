import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'
import { About } from './components/about'
import { Services } from './components/Services'
import { Gallery } from './components/gallery'
import { Testimonials } from './components/testimonials'
import { Footer } from './components/footer'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import { Contact } from './components/contact'
import { Team } from './components/Team'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services title={landingPageData.SeaServiceTitle} details={landingPageData.SeaServiceDetails} data={landingPageData.SeaServices} />
      <Services title={landingPageData.AirServiceTitle} details={landingPageData.AirServiceDetails} data={landingPageData.AirServices} />
      <Services title={landingPageData.DocumentationServiceTitle} details={landingPageData.DocumentationServiceDetails} data={landingPageData.DocumentationServices} />
      {/* 
      <Gallery />
      <Testimonials data={landingPageData.Testimonials} /> 
    */}
      <Team data={landingPageData.Team}/>
      <Contact data={landingPageData.Contact}/>
      <Footer />
    </div>
  )
}

export default App
